import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import styled from "@emotion/styled";
import noop from "lodash/noop";
import ReactMarkdown from 'react-markdown';

const StyledTypography = styled(Typography)(() => ({
  fontSize: 18,
  fontWeight: 600,
  wordWrap: "inherit",
  whiteSpace: "nowrap",
  width: "inherit",
}));

export const StyledBox = ({ heading, def }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "start", margin: "0px 10px", alignItems: "baseline" }}>
      <StyledTypography textAlign='center'>{heading}</StyledTypography>
      <Typography variant="body1" sx={{ color: "black", fontWeight: 500, margin: "0px 10px" }}>
        {def}
      </Typography>
    </Box>
  );
};
StyledBox.propTypes = {
  heading: PropTypes.string,
  def: PropTypes.string,
};

const ReadMoreModal = ({ openReadMoreModal = false, handleInstructionModal = noop, title, desc }) => (
  <Dialog
    open={openReadMoreModal}
    PaperProps={{
      sx: {
        width: '90%', // Adjust the width as needed
        maxWidth: '900px', // Optional: Set a maximum width
      },
    }}
    sx={{ zIndex: '999 !important' }}
  >
    <DialogContent>
      <Typography textAlign='center' variant="h5" sx={{ color: "#EE851E", fontWeight: 600, margin: "0px 10px", p: "10px 0px", borderBottom: '1px solid #d8d5d1' }} >
        {title === 'text_input' ? "Text Summary" : title}
      </Typography>
      <Typography fontWeight={500} variant="body1" sx={{ margin: "0px 10px", p: "10px 0px", lineHeight: 2.5 }}>
        <ReactMarkdown>{desc}</ReactMarkdown>
      </Typography>
    </DialogContent>

    <DialogActions sx={{ justifyContent: "center", mb: 1 }}>
      <Button variant='' onClick={handleInstructionModal} sx={{ fontSize: "16px", backgroundColor: '#EE851E !important', textAlign: 'center', paddingTop: '5px !important', color: 'white' }}>
        {"Got it !"}
      </Button>
    </DialogActions>
  </Dialog>
);

ReadMoreModal.propTypes = {
  openReadMoreModal: PropTypes.bool,
  handleInstructionModal: PropTypes.func,
};

export default ReadMoreModal;
