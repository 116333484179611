import { ThemeProvider } from "@mui/material";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import myTheme from "../src/components/theme/theme";
import "./App.scss";
import { HelmetProvider } from "react-helmet-async";
import PDFSummarizer from "../src/components/PDFSummarizer/PDFSummarizer";
import Login from "./components/auth/Login";
import axios from "axios";
import { useEffect, useState } from "react";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      setIsLoggedIn(true);
    } else {
      delete axios.defaults.headers.common["Authorization"];
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <HelmetProvider>
      <ThemeProvider theme={myTheme}>
        <BrowserRouter>
          <Routes>
            <Route>
              <Route path="/" element={isLoggedIn ? <Navigate to="/SmartDigest" replace /> : <Login />} />
              {/* <Route index element={<Login />} /> */}
              <Route path="SmartDigest" element={<PDFSummarizer />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
