import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  IconButton,
  TextField,
  Divider,
  Paper
} from "@mui/material";
import axios, { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { InfinitySpin } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "./Style.scss";
// import { ArticleSummrizationApi, GetAllArticlesApi, langChainApi } from "../../api_config/api_services";
import useFileUpload from "../hooks/useFileUpload";
import SelectedFiles from "../common/SelectedFiles";
// import { useSelector } from "react-redux";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import Header from "../header/Header";
import VerticalCardList from "../VerticalCardList/VerticalCardList";
import { Close as CloseIcon } from "@mui/icons-material"; // Import an icon, e.g., CloseIcon
import MenuIcon from "@mui/icons-material/Menu";
import Upload from '../assets/images/upload.png';
import ReactMarkdown from 'react-markdown';


const fileTypes = ["PDF", "DOCX"];
function PDFSummarizer() {
  const [open, setOpen] = useState(false);
  const [questionAnswer, setQuestionAnswer] = useState();
  const [isSideVisible, setSideVisible] = useState(true);
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [isEmptyMessage, setEmptyMessage] = useState(false);
  const [subType, setSubType] = useState("both");
  const [viewMode, setViewMode] = useState('paragraph'); // State to manage view mode
  const [articles, setArticles] = useState([]);
  const [modalOpen, setModalOpen] = useState(false); // State to manage modal visibility
  const [button, setButton] = useState("left");
  const [articleIndex, setArticleIndex] = useState('');
  const [type, setType] = useState(1);
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [history, setHistory] = useState([]);

  // const handleBackClick = () => {
  //   setChatEnabled(false);
  // };

  // Open the modal
  const handleOpenModal = (index) => {
    setModalOpen(true);
    setArticleIndex(index);
  }

  // Close the modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Handle view mode selection
  const handleViewModeChange = (event) => {
    setViewMode(event.target.value);
    if (event.target.value === 'paragraph') {
      setType(1);
    } else if (event.target.value === 'bullet') {
      setType(2);
    } else {
      setType(3);
    }
  };


  const scrollBottom = useRef(null);
  const scrollToBottom = () => {
    const lastChildElement = scrollBottom.current?.lastElementChild;
    lastChildElement?.scrollIntoView({ behavior: "smooth" });
  };

  // const username = useSelector((state) => state?.auth?.username)

  const { files, setFiles, isChatEnabled, setChatEnabled, handleChange, handleDeletedFiles, onDrop, onSelect, onSizeError, onTypeError } = useFileUpload()

  useEffect(() => {
    scrollToBottom();
  }, [questionAnswer]);
  const email = 'admin'

  const notifyError = (message) => toast.error(message);

  const sessionToken = localStorage.getItem("token")

  const apiCallPdfSummrization = async (formData) => {
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJqa25lY2h0QHByb3RldXMuY28iLCJleHAiOjE3MjMyMTI4Mjl9.AQPCYKuUlpyuhMdAtEd9SE5VuVu29yI2CrOzBibM1Ys"; // Bearer token
    setOpen(true);
    try {
      const response = await axios.post('https://smartdigest.netsmartz.us/summary/summarize_files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // If formData is a FormData object
          'Authorization': `Bearer ${sessionToken}`
        }
      });
      const result = response.data;
      setQuestionAnswer(result?.summaries);
      setFiles([]);
      setOpen(false);
      setChatEnabled(true);
      fetchSummaryHistory()
      // setViewMode('paragraph'); // Uncomment if you need this line
    }
    catch (error) {
      // Handle request errors
      const errorMessage = error.response?.data?.message || "An error occurred while processing your request.";
      notifyError(errorMessage);
      setOpen(false);
      // setChatEnabled(false);
    }
  };

  useEffect(() => {
    fetchSummaryHistory()
  }, [])

  const handleBackClick = () => {
    setChatEnabled(false);
  };



  const fetchSummaryHistory = async () => {
    setOpen(true);
    try {
      const response = await axios.get("https://smartdigest.netsmartz.us/summary/summary_history", {
        headers: {
          Authorization: `Bearer ${sessionToken}`, // Replace with your actual bearer token
          // Add any additional headers here if required
        },
      });
      console.log(response, "response")

      // Sort the response data by 'created_at' in descending order (newest to oldest)
      const sortedHistory = response?.data.sort((a, b) => {
        const dateA = new Date(a.created_at); // Convert to Date object
        const dateB = new Date(b.created_at); // Convert to Date object
        return dateB - dateA; // Sort by descending order (newest to oldest)
      });

      setHistory(sortedHistory);
      setOpen(false);
    } catch (err) {
      // Handle errors
      const errorMessage = err.response?.data?.message || "An error occurred while processing your request.";
      notifyError(errorMessage);
      setOpen(false);
    }
  };


  const handleSummarize = async () => {
    setOpen(true);
    try {
      const response = await axios.post(
        "https://smartdigest.netsmartz.us/summary/summarize_text",
        { text: query },
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`, // Replace with your actual bearer token
            "Content-Type": "application/json", // Optional, since axios sets this automatically for JSON data
          },
        }
      );

      setQuestionAnswer(response?.data?.summary)
      setFiles([])
      setOpen(false);
      setChatEnabled(true);
      fetchSummaryHistory()
    } catch (err) {

      // Notify error and set response message
      notifyError(err.message);
      setOpen(false);
    }
  };


  const handleUpload = async () => {



    if (query === '') {
      notifyError("Please add the Text for Translation.");
    }
    else {
      handleSummarize()
    }

    if (files.length === 0) {
      notifyError("No file uploaded.");
      return;
    }

    // Check if any of the files are empty
    const emptyFile = files.find((file) => file.size === 0);
    if (emptyFile) {
      notifyError("The uploaded file has no content.");
      return;
    }

    setOpen(true);
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append(`files`, file);
    });

    if (button === 'left') {
      apiCallPdfSummrization(formData)
    } else {
      handleSummarize()
    }
  };
  // const handleToggleViewMode = () => {
  //   setViewMode((prevMode) => (prevMode === 'paragraph' ? 'bullet' : 'paragraph'));
  // };



  const renderChatEnabled = () => {
    return (
      <Box sx={{
        width: "100%",
        height: "620px", // Adjust height as needed
        overflow: "auto",
        '&::-webkit-scrollbar': {
          display: 'none', // Hide scrollbar for WebKit browsers
        },
        scrollbarWidth: 'none', // Hide scrollbar for Firefox
        msOverflowStyle: 'none', // Hide scrollbar for IE and Edge
      }}>
        <div>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader
              className="pdf-card-header"
              titleTypographyProps={{ variant: "h6" }}
              avatar={
                <Avatar sx={{ bgcolor: "#EE851E" }} aria-label="recipe">
                  <ArrowBackIcon onClick={handleBackClick} />
                  {/* <PictureAsPdfIcon /> */}
                </Avatar>
              }
              title={"Summary"}
            />
            {button === 'right' ?
              <CardContent sx={{ paddingLeft: 5, paddingRight: 5, paddingBottom: 5 }}>
                <Typography style={{ lineHeight: 2.5 }} >
                  <ReactMarkdown>{questionAnswer}</ReactMarkdown>
                </Typography>
              </CardContent>
              :
              <CardContent sx={{ paddingLeft: 5, paddingRight: 5, paddingBottom: 5 }}>
                {Object.keys(questionAnswer).map((title, index) => {
                  const summary = questionAnswer[title];
                  return (
                    <div key={title} style={{ marginBottom: '20px' }}>
                      <Paper elevation={5} sx={{ padding: 4 }}>
                        <Typography variant="h6" component="h2" style={{ marginBottom: '10px' }}>
                          {"File Name: " + title}
                        </Typography>
                        <Typography style={{ lineHeight: 2.5 }}>

                          <ReactMarkdown>{summary}</ReactMarkdown>
                        </Typography>
                      </Paper>
                      {index < Object.keys(questionAnswer).length - 1 && <Divider />} {/* Add a divider except after the last item */}
                    </div>
                  );
                })}
              </CardContent>

            }
          </Card>
        </div>
      </Box>
    );
  }



  const items = [
    { id: 1, text: 'Customer Reviews Summarization' },
    { id: 2, text: 'Customer Support Ticket Summarization' },
    { id: 3, text: 'Human Resources Document Summarization' },
    { id: 4, text: 'Meeting Minutes Compilation' },
    { id: 5, text: 'Market Research Reports' },
    { id: 6, text: 'Educational Material Condensation' },
    { id: 7, text: 'Legal Document Analysis' },
    { id: 8, text: 'News Aggregation' },
    { id: 9, text: 'Medical Records Summarization' },
    { id: 10, text: 'Business Intelligence Briefs' },
    { id: 11, text: 'Patent Analysis' },
    { id: 12, text: 'Scientific Paper Summaries' },
    { id: 13, text: 'Technical Documentation Summarization' },
    { id: 14, text: 'Financial Report Analysis' },
    { id: 15, text: 'Policy Document Simplification' },
    { id: 16, text: 'Insurance Claim Summarization' },
  ]

  const CaseStudies = [
    {
      id: 1,
      title: "Customer Feedback Insights",
      description: "A consumer goods company utilized our AI summarization tool to analyze customer feedback from various channels. By generating concise summaries of customer sentiments and preferences, they gained valuable insights for product development and marketing strategies, leading to increased customer satisfaction and loyalty."
    },
    {
      id: 2,
      title: "Redefining Social Media Strategies",
      description: "Our AI summarization tool enabled a digital marketing agency to analyze social media conversations and trends efficiently. By summarizing vast amounts of social media data into actionable insights, they optimized their clients' social media strategies, leading to increased engagement and brand visibility."
    },
    {
      id: 3,
      title: "Empowering Financial Decision-Making",
      description: "A financial consultancy firm utilized our summarization tool to streamline investment research and analysis. By summarizing lengthy financial reports and market analyses, they could swiftly extract crucial information, enabling clients to make informed decisions with confidence and agility in a dynamic market landscape."
    }
  ]

  const handleLinkClick = (article_link) => {
    const url = article_link;
    navigator.clipboard.writeText(url).then(() => {
      alert('Article Url Copied to Clipboard.');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  useEffect(() => {
    const username = "admin"
    const email = username.toLowerCase();
    const validEmails = ['gv@dyreid.no', 'shyam@dyreid.no', 'himmat.pratap@netsmartz.com', 'himmat.pratap.singh.gill@netsmartz.com', 'rahul.miglani@netsmartz.com', 'admin121@gmail.com'];

    // if (email.includes('rahul') || email.includes('himmat') || email.includes('admin')) {
    //   setSubType("both");
    //   // setBoth(true)
    // } else if (email.includes('shyam') || email.includes('hilde') || email.includes('gv') || email.includes('gudbrand')) {
    //   // apiCallGetArticles();
    //   setSubType("Articles");
    //   setSideVisible(false);
    // } else {
    //   setSubType("PDF");
    // }
  }, [email]);

  const renderLandingSection = () => {
    if (subType === "both") {
      return (
        <Box>
          {/* Add IconButton here */}
          {history && history.length > 0 &&
            <IconButton
              onClick={() => setDrawerVisible(!isDrawerVisible)}
              sx={{
                position: "absolute",
                // top: 0,
                right: 40,
                m: 1.3, // Margin for spacing
              }}
            >
              <MenuIcon />
            </IconButton>
          }
          <Box display="flex" flexDirection={'column'} justifyContent={'center'}>
            <Box display="flex" flexDirection={'column'}>
              <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: "center", }}>

                <Box sx={{ marginBottom: '20px', marginTop: '15px' }} className="mask-box" position="relative">
                  <Box
                    className="mask"
                    sx={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      transition: "transform 0.3s ease",
                      transform: `translateX(${button === "left" ? '0%' : '100%'})`,
                      backgroundColor: '#654320',
                      zIndex: 1,
                      borderRadius: '4px'
                    }}
                  />
                  <Button
                    disableRipple
                    variant="text"
                    sx={{
                      color: button === "left" ? "#ffffff" : "#654320",
                      zIndex: 2,
                      width: '100px'
                    }}
                    onClick={() => {
                      setButton("left");
                      setSideVisible(true);
                      setQuery('')
                    }}
                  >
                    PDF
                  </Button>
                  <Button
                    disableRipple
                    variant="text"
                    sx={{
                      color: button === "right" ? "#ffffff" : "#654320",
                      zIndex: 2,
                      width: '100px'
                    }}
                    onClick={() => {
                      // apiCallGetArticles();
                      setButton("right");
                      setSideVisible(false);
                      setFiles([])
                    }}
                  >
                    TEXT
                  </Button>
                </Box>
              </Box>
              {button === 'left' ? (
                <Box height={'75vh'} display="flex" flexDirection={'column'} justifyContent={'center'}>
                  <Box padding="10px" marginBottom="30px" display="flex" flexDirection={'column'} justifyContent="center" alignItems="center">
                    <Typography variant="h3">Please upload your <span className="text-decorat">files</span> here.</Typography>
                    <Typography className="mt-2" variant="h6">Note: Multiple files <span className="text-decorat">(PDF, DOCX)</span> upload supported.</Typography>
                  </Box>
                </Box>
              ) : (

                // <div className="scrollbar" style={{ overflowX: 'auto' }}>
                //   <div id="services" className="sm-container">
                <Box height={'75vh'} display="flex" flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                  <TextField
                    sx={{
                      minWidth: '700px',
                      minHeight: '200px',
                      border: 'none',
                      boxShadow: 10,
                      borderRadius: 2,
                      backgroundColor: '#f7f7f7',
                      margin: 5,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                        },
                      },
                    }}
                    placeholder={`Please write your thoughts to get the Summary.`}
                    multiline
                    rows={7}
                    variant="outlined"
                    onChange={(e) => setQuery(e.target.value)}
                  />

                  <div>
                    <Button
                      // className="upload-button"
                      variant="contained"
                      color="secondary"
                      onClick={handleUpload}
                      disabled={query === ''}
                      sx={{
                        marginTop: "20px",
                        width: "120%",
                        // padding: '12px',
                        backgroundColor: "#3d3d3d", // Ensure this style is applied
                        color: "white",           // Ensure this style is applied
                        '&:hover': {
                          backgroundColor: "black !important", // Optional: change color on hover
                        }
                      }}
                    >
                      Generate Summary
                    </Button>
                  </div>
                </Box>

                //   </div>
                // </div>
              )}
            </Box>
          </Box>
        </Box>
      )
    }

    else if (subType === "PDF") {
      return (
        <Box height={'75vh'} display="flex" flexDirection={'column'} justifyContent={'center'}>
          {subType === 'PDF' && (
            <Box padding="10px" marginBottom="30px" display="flex" flexDirection={'column'} justifyContent="center" alignItems="center">
              <Typography variant="h3">Please upload a <span className="text-decorat">pdf</span> file.</Typography>
              <Typography className="mt-2" variant="h6">Note: Single file upload supported.</Typography>
            </Box>
          )}
        </Box>
      );
    }
    else {
      return (
        <Box height={'75vh'} display="flex" flexDirection={'column'} justifyContent={'center'}>
          <div className="scrollbar" style={{ overflowX: 'auto' }}>
            <div id="services" className="sm-container">
              {Object.keys(articles).map(category => (
                <div key={category}>
                  <h2 style={{ textAlign: 'center', marginBottom: '30px', marginTop: '20px' }}>{category}</h2>
                  <Grid container spacing={5} paddingBottom="10px" paddingLeft="40px" paddingRight="40px">
                    {articles[category]?.map((data, index) => (
                      <Grid
                        style={{ display: 'flex' }}
                        justifyContent="center"
                        item
                        xs={12}
                        key={index}
                        sm={6}
                        md={3}
                      >
                        {/* <ArticlesCardList
                          key={index}
                          image={data.image_link}
                          title={data.title}
                          descriptions={''}
                          poweredBy={'true'}
                          onCardClick={() => handleOpenModal(data.id)}
                          handleLinkClick={() => handleLinkClick(data?.article_link)}
                        /> */}
                      </Grid>
                    ))}
                  </Grid>
                </div>
              ))}
            </div>
          </div>
        </Box>
      );
    }


    // return (
    //   <Box height={'75vh'} display="flex" flexDirection={'column'}>
    //     <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: "center", mb: 3 }}>

    //       <Box sx={{ marginBottom: subType === "PDF" ? '130px' : '20px', marginTop: '15px' }} className="mask-box">
    //         <Box
    //           className="mask"
    //           style={{
    //             transform: `translateX(${subType === "PDF" ? 0 : "180px"})`,
    //           }}
    //         />
    //         <Button
    //           disableRipple
    //           variant="text"
    //           sx={{ color: subType === "PDF" ? "#ffffff" : "#654320" }}
    //           onClick={() => (
    //             setSubType("PDF"),
    //             setSideVisible(true)
    //           )}
    //         >
    //           PDF
    //         </Button>
    //         <Button
    //           disableRipple
    //           variant="text"
    //           sx={{ color: subType === "Articles" ? "#ffffff" : "#654320" }}
    //           onClick={() => (
    //             apiCallGetArticles(),
    //             setSubType("Articles"),
    //             setSideVisible(false)
    //           )}
    //         >
    //           Articles
    //         </Button>
    //       </Box>
    //     </Box>
    //     {subType === 'PDF' ? (
    //       <Box padding="10px" marginBottom="30px" display="flex" flexDirection={'column'} justifyContent="center" alignItems="center">
    //         <Typography variant="h3">Please upload a <span className="text-decorat">pdf</span> file.</Typography>
    //         <Typography className="mt-2" variant="h6">Note: Single file upload supported.</Typography>
    //       </Box>
    //     )
    //       : (
    //         <div className="scrollbar" style={{ overflowX: 'auto' }}>
    //           <div id="services" className="sm-container">
    //             {Object.keys(articles).map(category => (
    //               <div key={category}>
    //                 <h2 style={{ textAlign: 'center', marginBottom: '30px', marginTop: '20px' }}>{category}</h2>
    //                 <Grid container spacing={5} paddingBottom="10px" paddingLeft="40px" paddingRight="40px">
    //                   {articles[category]?.map((data, index) => (
    //                     <Grid
    //                       style={{ display: 'flex' }}
    //                       justifyContent="center"
    //                       item
    //                       xs={12}
    //                       key={index}
    //                       sm={6}
    //                       md={3}
    //                     >
    //                       <ArticlesCardList
    //                         key={index}
    //                         image={data.image_link}
    //                         title={data.title}
    //                         descriptions={''}
    //                         poweredBy={'true'}
    //                         onCardClick={() => handleOpenModal(data.id)}
    //                         handleLinkClick={() => handleLinkClick(data?.article_link)}
    //                       />
    //                     </Grid>
    //                   ))}
    //                 </Grid>
    //               </div>
    //             ))}
    //           </div>
    //         </div>
    //       )}
    //   </Box>


    // );

  }



  return (
    <>
      <Header />
      <Box height={"100vh"} display="flex" flexDirection="column">
        {/* Header */}

        <Grid container sx={{ flex: 1, marginTop: '70px' }}>
          <Grid item xs={12} lg={15} m={2}>
            {/* Horizontal List */}
            {/* <HorizontalList items={items} /> */}

            {/* Chat Container */}
            {/* <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ backgroundColor: "#f7f7f7", pt: 4, flex: 1 }}
          > */}
            <Grid item sm={8} lg={12} borderRadius={'8px'}>
              <>
                {/* <ToastContainer position="bottom-right" autoClose={2000} /> */}

                <Grid container sx={{ height: "87vh" }} >

                  {/* Left Grid */}
                  {isSideVisible &&
                    <Grid
                      item
                      md={2.5}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      // className="grid-left-pane"
                      // bgcolor={"#EE851E"}
                      sx={{
                        background: 'linear-gradient(to right, #FFC170, #EE851E)', // Example gradient with #EE851E
                        borderRadius: '8px',
                      }}
                      borderRadius={"8px"}
                      mr={1}
                    >
                      <Box sx={{ width: "80%" }}>
                        <FileUploader
                          className="uploader"
                          fileOrFiles={files}
                          onTypeError={onTypeError}
                          handleChange={handleChange}
                          name="file"
                          types={fileTypes}
                          onSizeError={onSizeError}
                          onDrop={onDrop}
                          onSelect={onSelect}
                          label="Upload file here"
                          dropMessageStyle={{ backgroundColor: "orange" }}
                          multiple={true}
                        >
                          <Box
                            sx={{
                              // borderStyle: "dashed",
                              // borderWidth: 2,
                              // width: "100%",
                              borderColor: "#ccc",
                              // borderRadius: 3,
                              // backgroundColor: NETSMARTZ_THEME_COLOR,
                              // background: '#FEA349', // Example gradient with #EE851E
                              paddingTop: 2,
                              paddingBottom: 2,
                              flexDirection: "column",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              // gap: 2,
                              height: "220px",
                              top: "238px",
                              // left: "50px",
                              gap: "0px",
                              opacity: "0px",
                              marginTop: -2,
                              backgroundImage: `url(${Upload})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center center',
                              backgroundSize: 'contain',
                            }}
                          >
                            {/* <CloudUploadIcon
                            style={{
                              width: "30px",
                              height: "30px",
                              color: "white",
                            }}
                          /> */}
                            <Typography align="center" fontWeight={"bold"} variant="h6" m={1} color={"white"}>Upload file</Typography>
                          </Box>
                        </FileUploader>
                        <div>
                          <Button
                            // className="upload-button"
                            variant="contained"
                            color="secondary"
                            onClick={handleUpload}
                            disabled={files.length === 0}
                            sx={{
                              marginTop: "20px",
                              width: "100%",
                              backgroundColor: "#3d3d3d", // Ensure this style is applied
                              color: "white",           // Ensure this style is applied
                              '&:hover': {
                                backgroundColor: "black !important", // Optional: change color on hover
                              }
                            }}
                          >
                            Generate Summary
                          </Button>
                        </div>
                      </Box>
                    </Grid>
                  }

                  {/* Right Grid */}
                  <Grid
                    item
                    md={isSideVisible && isDrawerVisible ? 6.6 : isSideVisible || isDrawerVisible ? 9.2 : 12}
                    display="flex"
                    mr={1}
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '8px'
                    }}
                    justifyContent="center"
                    className="grid-right-pane"
                  >
                    {isChatEnabled ? (
                      renderChatEnabled()
                    ) : (
                      <>
                        {files?.length !== 0 ? (
                          <SelectedFiles files={files} handleDeletedFiles={handleDeletedFiles} />
                        ) : (
                          <Box display="flex" flexDirection={'column'} alignItems="center">
                            {renderLandingSection()}
                          </Box>
                        )}
                      </>
                    )}
                  </Grid>
                  {isDrawerVisible && (
                    <Grid
                      item
                      md={2.7}
                      display="flex"
                      borderRadius={"8px"}
                    >
                      <VerticalCardList items={history} />
                    </Grid>
                  )}
                </Grid>

                {open ? (
                  <div className="spinner-container">
                    <InfinitySpin width="200" color="#F86F03" />
                  </div>
                ) : null}

                {isEmptyMessage ? (
                  <Alert
                    severity="error"
                    onClose={() => {
                      setEmptyMessage(false);
                    }}
                  >
                    Message can't be empty!
                  </Alert>
                ) : null}
              </>
            </Grid>
            {/* </Grid> */}

          </Grid>

          {/* Vertical List of Cards */}

          {/* <VerticalCardList items={CaseStudies} /> */}
        </Grid>
      </Box>
    </>
  );
}

export default PDFSummarizer;
