import { useState } from 'react';
import { toast } from 'react-toastify';

const useFileUpload = () => {
    const [files, setFiles] = useState([]);
    const [responseMessage, setResponseMessage] = useState({
        type: '',
        message: '',
        isVisible: false,
    });
    const [isChatEnabled, setChatEnabled] = useState(false);
    const notifyError = (message) => toast.error(message);

    const handleChange = (fileOrFiles) => {
        if (Object.keys(fileOrFiles).length>0) {
            setFiles((prevFiles) => [...prevFiles, ...fileOrFiles]);
        }
        else {
            setFiles([fileOrFiles])
        }
    };

    const onDrop = (fileOrFiles) => {
        console.log("drop", fileOrFiles);
    };

    const onSelect = (fileOrFiles) => {
        setChatEnabled(false);
    };
    const onTypeError = (err = 1) => {
        notifyError(`${err}` || "File type is not supported.")

    }

    const onSizeError = (err = 1) => console.log(err);

    const handleDeletedFiles = (index) => {
        const arr = [...files];
        arr.splice(index, 1);
        setFiles(arr);
    };

    return {
        files,
        setFiles,
        responseMessage,
        isChatEnabled,
        setChatEnabled,
        handleChange,
        onDrop,
        onSelect,
        onTypeError,
        onSizeError,
        handleDeletedFiles,
    };
};

export default useFileUpload;
